import React from "react"
import LDVELHContent from "../../components/LDVELHContent"
import Landing from "../../templates/Landing"

interface Props {}

const MemoQuest: React.FC<Props> = () => {
  return (
    <Landing>
      <LDVELHContent />
    </Landing>
  )
}

export default MemoQuest
