import React from "react"
import { Box, Flex } from "@chakra-ui/core"
import Wrapper from "../components/Wrapper"
import Navigation from "../components/Navigation"
import Branding from "../components/Branding"
import Footer from "../components/Footer"
import "./style.scss"

interface Props {}

const Website: React.FC<Props> = ({ children }) => {
  return (
    <>
      <Box as="main" backgroundColor="gray.700" color="gray.200">
        <Wrapper padding={false}>{children}</Wrapper>
      </Box>
      <Box as="footer" backgroundColor="gray.700" color="gray.200">
        <Wrapper padding={false}>
          <Footer />
        </Wrapper>
      </Box>
    </>
  )
}

export default Website
